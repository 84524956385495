import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { UserType } from "src/app/models/directory/enums/UserType.enum";
import { GraphApplication, IGraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { IGraphApplicationPermissions } from "src/app/models/graph/applications/GraphApplicationPermissions";
import { GraphAppRequestModel } from "src/app/models/graph/applications/GraphAppRequest";
import { GraphAppType } from "src/app/models/graph/enums/GraphAppType.enum";
import { IGraphUser } from "src/app/models/graphUser";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { DialogAppSearchComponent } from "../../_shared/dialog-app-search/dialog-app-search.component";
import { DialogDirectorySearchComponent } from "../../_shared/dialog-directory-search/dialog-directory-search.component";
import { AppRegDomainValidator } from "src/app/helpers/validators/appRegDomainValidator";
import { IDialogDirectoryData } from "src/app/models/components/IDialogDirectoryData";
import { IDialogAppSearchData } from "src/app/models/components/IDialogAppSearchData";
import { lastValueFrom } from "rxjs";
import { ValidationPatternConstants } from "src/app/constants/ValidationPattern.constants";
import { DialogAppSearchChevronComponent } from "../../_shared/dialog-app-search-chevron/dialog-app-search-chevron.component";
import { IDialogAppSearchChevronData } from "src/app/models/idm/applications/IDialogAppSearchChevronData";
import { IIdmApplication } from "src/app/models/idm/applications/IdmApplication";
import { ToStringHelper } from "src/app/helpers/ToStringHelper";

@Component({
    selector: 'app-create-application',
    templateUrl: './create-application.html',
    styleUrls: ['./create-application.css']
})

export class CreateApplicationComponent implements OnInit {

    chevronAppEnvironments: string[] = [];

    PageLayout = PageLayout;
    currentUserProfile: ICvxClaimsPrincipal;
    isCreating: boolean = false;
    createdApp: IGenericApiResponseWithWorkflowRequest<IGraphApplication> | undefined;
    displayCreatedApp: any;
    createErrorMessage = { message: '', errors: [] };
    urlValidatorFunctions = [
        Validators.required,
        AppRegDomainValidator(),
        Validators.maxLength(256),
        Validators.pattern(ValidationPatternConstants.URLPattern)
    ]

    appForm = this.formBuilder.group({
        type: ['', Validators.required],
        chevronApp: [null as IIdmApplication | null, Validators.required],
        name: ['', Validators.required],
        uri: ['', this.urlValidatorFunctions],
        urls: this.formBuilder.array([this.formBuilder.control('',
            this.urlValidatorFunctions
        )]),
        owners: this.formBuilder.array([], Validators.minLength(2))
    });

    get chevronApp() { return this.appForm.get('chevronApp')?.value?.displayName ?? ''; }

    get urls() { return this.appForm.get('urls') as FormArray; }

    get owners() { return this.appForm.get('owners') as FormArray; }

    get appTypes() { return Object.values(GraphAppType) };

    get appFormValues() {
        let values = this.appForm.value;
        let serviceId = values.chevronApp?.serviceId ? parseInt(values.chevronApp?.serviceId) : undefined;
        let urls = [...this.urls.value];
        urls.splice(0, 1);
        let payload = new GraphAppRequestModel(values.type!, values.name!, values.uri!,
            this.owners.value.map((item: IGraphUser) => item.id),
            urls, // remove the app uri because it gets automatically added
            this.additionalPermissions,
            serviceId);
        return payload;
    }

    additionalPermissions: IGraphApplicationPermissions[] = [];

    additionalPermissionsDataSource = new MatTableDataSource<IGraphApplicationPermissions>();
    additionalPermissionsColumns = ['app', 'permissions', 'select'];

    constructor(
        private authService: CalAngularService,
        private dialog: MatDialog,
        private graphApplicationService: GraphApplicationService,
        private formBuilder: FormBuilder
    ) { }

    async ngOnInit(): Promise<void> {
        await this.addCurrentUserAsOwner();
    }

    // default add the current user as an owner
    async addCurrentUserAsOwner() {
        if (await lastValueFrom(this.authService.isUserSignedIn())) {
            this.currentUserProfile = this.authService.cvxClaimsPrincipal;
            this.addOwner(this.currentUserProfile.objectId, this.currentUserProfile.name, this.currentUserProfile.email);
        }
    }

    addOwner(id: string, name: string, email: string) {
        this.owners.push(this.formBuilder.group({
            id: [id],
            displayName: [name],
            mail: [email]
        }));
    }

    searchForPrimaryUser(type: string): void {
        const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
            disableClose: true,
            autoFocus: true,
            maxWidth: 1000,
            width: '100%',
            data: {
                type: GroupMemberType.User,
                domain: DirectoryDomain.Chevron,
                userType: UserType.Primary,
                filterGroupRemoveNotAvailableAsGroupMember: false,
                filterGroupRemoveDynamicMembershipEnabled: false,
                filterGroupOnlyManagedByIdamp: false
            } as IDialogDirectoryData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.addOwner(result.id, result.displayName, result.mail);
            }
        });
    }

    removeOwner(i: number) {
        this.owners.removeAt(i);
    }

    searchForApplicationPermissions(): void {
        const appDialogRef = this.dialog.open(DialogAppSearchComponent, {
            disableClose: true,
            autoFocus: true,
            maxWidth: 1000,
            width: '100%',
            data: {
                servicePrincipals: true,
                searchPermissions: true
            } as IDialogAppSearchData
        });

        appDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.additionalPermissions.push(result);
                this.additionalPermissionsDataSource.data = this.additionalPermissions;
            }
        });
    }

    // uri sets the default reply url
    onUriChange(uri: any): void {
        this.urls.controls[0].setValue(uri.value);
    }

    // add additional inputs for different urls
    addUrl() {
        this.urls.push(this.formBuilder.control('', this.urlValidatorFunctions));
    }

    // remove a url from the list
    removeUrl(i: number) {
        this.urls.removeAt(i);
    }

    // remove app permission from the list of additional requested permissions
    removeAppPermission(row: IGraphApplicationPermissions): void {
        this.additionalPermissions.forEach((item, index) => {
            if (item === row) this.additionalPermissions.splice(index, 1);
        });
        this.additionalPermissionsDataSource.data = this.additionalPermissions;
    }

    async onSubmit() {

        console.log(this.appForm.controls.owners.errors)
        // clear error messages
        this.createErrorMessage.message = '';
        this.createErrorMessage.errors = [];

        if (this.appForm.valid) {

            this.isCreating = true;
            let payload = this.appFormValues;

            const observer = {
                next: (x: IGenericApiResponseWithWorkflowRequest<IGraphApplication>) => {
                    this.createdApp = x;

                    let data = x.data as GraphApplication;

                    this.displayCreatedApp = {
                        name: data.displayName,
                        appId: data.appId,
                        servicePrincipalId: data.servicePrincipalId,
                        identifierUris: data.identifierUris
                    };
                },
                error: (err: any) => {
                    this.createErrorMessage.message = err.statusText;
                    this.createErrorMessage.errors = err?.error?.errors ?? [];
                    this.isCreating = false;
                },
                complete: () => {
                    this.isCreating = false;
                }
            };

            let createCall = this.graphApplicationService.CreateApplication(payload);
            createCall.subscribe(observer);
        }
        else {
            // form is invalid, trigger all validation fields
            this.appForm.markAllAsTouched();
        }
    }

    async resetForm() {
        this.appForm.reset();
        this.appForm.controls.urls.clear();
        this.appForm.controls.urls.push(this.formBuilder.control(''));
        this.appForm.controls.owners.clear();
        this.additionalPermissions = [];
        this.additionalPermissionsDataSource.data = [];
        this.chevronAppEnvironments = [];
        await this.addCurrentUserAsOwner();
    }

    searchForChevronApplication(): void {
        const appDialogRef = this.dialog.open(DialogAppSearchChevronComponent, {
            disableClose: true,
            autoFocus: true,
            maxWidth: 1000,
            width: '100%',
            data: {
                includeEnvironments: false
            } as IDialogAppSearchChevronData
        });

        appDialogRef.afterClosed().subscribe(result => {
            if (result) {
                var chevronApp = result as IIdmApplication;

                // override toString method to display displayName (as form control value will display [object Object] without this)
                chevronApp = ToStringHelper.overrideToString(chevronApp);
                this.appForm.patchValue({ chevronApp: chevronApp });
                this.chevronAppEnvironments = chevronApp.environments;
            }
        });
    }

    removeEnvironment(env: string): void {
        this.chevronAppEnvironments = this.chevronAppEnvironments.filter(e => e !== env);

        if (this.chevronAppEnvironments.length === 0) {
            // remove the chevron app if no environments are selected
            this.appForm.patchValue({ chevronApp: null });
        }
    }

}