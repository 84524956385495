<mat-dialog-content>

    <div class="stickyHeader">
        <a class="close-button stickyHeader" mat-dialog-close><span class="icon-remove close-button"></span></a>
        <h2>batch {{ data.type | lowercase }} lookup</h2>
    </div>

    <div class="dialog-wrapper adjustStickyHeader">
        @if (screenMessage !== '')
        {
        <div class="container mb-3">
            <p class="text-danger text-center">{{screenMessage}}</p>
        </div>
        }

        <div class="row">
            <div class="col-12">
                <div class="alert alert-info">
                    <p>Semi-colon separated list of identifiers such as email, samaccountname, or provisioningId</p>
                    <p>Example</p>
                    <p>John.Smith&#64;chevron.com;janed;12345678</p>
                </div>

                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                    <mat-label>{{ data.type | lowercase }} identifiers</mat-label>
                    <textarea [formControl]="searchTerms" matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="20"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="directory-search-wrapper">
            <generic-search-results [makeSticky]="true" [showHeader]="false" [showCounter]="false" [title]="''"
                [records]="resolvedObjects" [displayedColumns]="resolvedCols" [selectableRecords]="false"
                [deletableRecords]="false" [allowLocalFilter]="false"
                [hidden]="resolvedObjects.length === 0 || isSearching"></generic-search-results>
        </div>

        <div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
            <div class="btn-group mr-2" role="group" [hidden]="searchTerms.value === ''">
                <button class="button" type="button" (click)="resolve()" [disabled]="isSearching">
                    <div class="button-spinner"><mat-spinner [diameter]="20" [hidden]="!isSearching"></mat-spinner>
                    </div>
                    lookup <div [hidden]="!isSearching">({{ numLoadedRecordsProgress }} loaded)</div>
                </button>
            </div>
            <div class="btn-group mr-2" role="group" [hidden]="resolvedObjects.length === 0 || isSearching">
                <button class="button" type="button" (click)="add()">add</button>
            </div>
            <div class="btn-group mr-2" role="group">
                <button class="button" type="button" (click)="reset()">reset</button>
            </div>
            <div class="btn-group mr-2" role="group">
                <button class="button" type="button" mat-dialog-close>close</button>
            </div>
        </div>
    </div>
</mat-dialog-content>