<form class="branded">
    <div class="row">
        @if(records !==undefined && records.length>0 && showBulkServiceIdSearch) {
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                matTooltip="New CMDB Application ID for all selected account">
                <mat-label>bulk service id</mat-label>
                <input matInput (click)="searchForChevronApplication()" [readonly]="true"
                    [value]="chevronApp?chevronApp:''">
                <button mat-icon-button matSuffix (click)="searchForChevronApplication()">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </div>
        }

        @if(records !== undefined && records.length>0 && showBulkStewardSearch) {
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                matTooltip="{{showBulkStewardTooltipMessage}}">
                <mat-label>{{showBulkStewardLabel}}</mat-label>
                <input matInput [readonly]="true" [value]="selectedSteward" (click)="searchForUsers()">
                <button mat-icon-button matSuffix (click)="searchForUsers()">
                    <mat-icon>person_search</mat-icon>
                </button>
            </mat-form-field>
        </div>
        }
    </div>
</form>
<table aria-describedby="title" #table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    @if(caption) { <caption>{{ caption }}</caption>}
    @for(disCol of displayedColumns; track disCol; let colIndex = $index){
    <ng-container matColumnDef="{{disCol}}">
        <th mat-header-cell *matHeaderCellDef>{{disCol | convertDisplayLabels}}</th>
        <td mat-cell *matCellDef="let element; let rowIndex = index">
            {{element[disCol]}}
        </td>
    </ng-container>}

    <ng-container matColumnDef="del">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="text-align:center;">
            <button mat-icon-button matSuffix (click)="deleteRow(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- Secondary Column -->
    <ng-container matColumnDef="secondary">
        <td mat-cell [attr.colspan]="cols.length" *matCellDef="let element; let rowIndex = dataIndex">
            <app-user-update-form #userUpdateForm [userInfo]="element"
                (onFormStatusChange)="onFormStatusChange($event, rowIndex)"
                (onFormValueChanges)="onFormValueChanges($event, rowIndex)"></app-user-update-form>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="cols"></tr>
    <tr mat-row *matRowDef="let row; columns: cols;"></tr>
    <tr mat-row *matRowDef="let row; columns: ['secondary']"></tr>
</table>