import { Injectable } from "@angular/core";
import { IStatusApiResponse } from "src/app/models/common/IStatusApiResponse";
import { IdampHttpClient } from "./IdampHttpClient";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { HttpErrorResponse } from "@angular/common/http";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";

@Injectable({ providedIn: 'root' })
export class ApiStatusService {
    constructor(private http: IdampHttpClient) { }

    acceptedCodes: number[] = [200, 424, 503];

    async getADStatus(): Promise<IStatusApiResponse[]> {
        try {
            const path = 'connections/directory-domain-services';
            let response = await this.http.GetAsync<IGenericApiResponse<IStatusApiResponse[]>>(path);

            let filterOnlyOnPrem = response.data.filter((x: IStatusApiResponse) => x.name.toLowerCase() != DirectoryDomain.Chevron.toLowerCase());

            return filterOnlyOnPrem;
        } catch (err) {
            if (err instanceof HttpErrorResponse) {
                let error = err as HttpErrorResponse;

                // 424, 503, 200 are accepted codes
                if (this.acceptedCodes.indexOf(error.status) >= 0) {
                    return error.error.data;
                }
            }
            throw err;
        }
    }
}