import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class DataFormatter {

    isDate(input: string): boolean {
        let regex: RegExp = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
        return regex.test(input);
    }

    formatDate(input: string): string {
        let date = new Date(input);
        // format locale string to limit what is shown
        return `${date.toLocaleString([], { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })}`;
    }

    formatBoolean(input: boolean): string {
        return input ? 'yes' : 'no';
    }

    format(input: any): string {
        //if it is number, return it immediately
        if (!Number.isNaN(Number(input))) {
            return input;
        }
        if (this.isDate(input) && this.formatDate(input) !== 'Invalid Date') {
            return this.formatDate(input);
        }
        if (input === true || input === false) {
            return this.formatBoolean(input);
        }
        // didn't match anything to be formatted, return the original input
        return input;
    }
}