<form [formGroup]="userDetailsForm" class="branded">
    <np-alert *ngIf="!isAllowedToManageUser && operationType==='edit'">
        <p>You are not authorized to manage this user. </p>
    </np-alert>

    <np-alert *ngIf="isContractorOrEmployeeJVPartnerOnManageUser && userIsActive && isAllowedToManageUser" >
        <p> Changes made to input fields will not be reflected upon deactivation. Consider clicking 'Update' button to ensure field values are updated prior to deactivation. </p>
    </np-alert>

    <np-alert *ngIf="(isEmployeeOnDeactivateUser || isEmployeeOnReactivateUser) && operationType === 'edit'" id="alert-shim-wrap">
        <p>{{isEmployeeOnReactivateUser ? 'Reactivation of previous' : 'Deactivation of'}} employee is not allowed on this page, please check this <a class="exception" href="https://chevron.service-now.com/myhr?id=kb_article_hr&sys_id=1c3d7e851beb41943ca586e7624bcb98">link</a> for more details.</p>
    </np-alert>    

    <fieldset [disabled]="isReadonly">

        <div class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>profile information</strong></p></div>

        <div *ngIf="operationType !== 'create'">

            <div class="row">

                <div class="col-12 col-sm-6 col-lg-3">
                    <mat-form-field appearance="outline" class="w-100"> 
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">email</mat-label>
                        <input matInput 
                            formControlName="email" 
                            readonly 
                            matTooltip="This is a read only field and can not be changed."
                            class="readonlyText">
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-6 col-lg-3">
                    <mat-form-field appearance="outline" class="w-100"> 
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">provisioningId</mat-label>
                        <input matInput 
                            formControlName="provisioningId" 
                            readonly 
                            matTooltip="This is a read only field and can not be changed."
                            class="readonlyText">
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-6 col-lg-3">
                    <mat-form-field appearance="outline" class="w-100"> 
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">cai</mat-label>
                        <input matInput 
                            formControlName="cai" 
                            readonly 
                            matTooltip="This is a read only field and can not be changed."
                            class="readonlyText">
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-6 col-lg-3">
                    <mat-form-field appearance="outline" class="w-100"> 
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">dataSource</mat-label>
                        <input matInput 
                            formControlName="dataSource" 
                            readonly 
                            matTooltip="This is a read only field and can not be changed."
                            class="readonlyText">
                    </mat-form-field>
                </div>

            </div>
            
        </div>

        <mat-divider class="mb-4"></mat-divider>

        <div class="row">

            <div class="col-12 col-sm-6 col-lg-4">        
                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">first name</mat-label>
                    <input matInput formControlName="firstname"
                        [readonly]="!isAllowedToEditNames()"
                        [matTooltip]="!isAllowedToEditNames() ? 'This is a read only field and can not be changed.' : ''">
                    <mat-error *ngIf="userDetailsForm.controls['firstname'].hasError('required')">This is a required field.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['firstname'].hasError('minlength')">Must be at least 1 character.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['firstname'].hasError('maxlength')">Can not be longer than 40 characters.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['firstname'].hasError('pattern') && !userDetailsForm.controls['firstname'].hasError('minlength') && !userDetailsForm.controls['firstname'].hasError('maxlength')">Can only contain the - ' , special characters, or a space.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['firstname'].hasError('allCaps') && !userDetailsForm.controls['firstname'].hasError('minlength') && !userDetailsForm.controls['firstname'].hasError('maxlength')">All upper case is not allowed on first name.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 col-sm-6 col-lg-4">
                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">last name</mat-label>
                    <input matInput formControlName="lastname"
                        [readonly]="!isAllowedToEditNames()"
                        [matTooltip]="!isAllowedToEditNames() ? 'This is a read only field and can not be changed.' : ''">
                    <mat-error *ngIf="userDetailsForm.controls['lastname'].hasError('required')">This is a required field.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['lastname'].hasError('minlength')">Must be at least 1 character.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['lastname'].hasError('maxlength')">Can not be longer than 40 characters.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['lastname'].hasError('pattern') && !userDetailsForm.controls['lastname'].hasError('minlength') && !userDetailsForm.controls['lastname'].hasError('maxlength')">Can only contain the - ' , special characters, or a space.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['lastname'].hasError('allCaps') && !userDetailsForm.controls['lastname'].hasError('minlength') && !userDetailsForm.controls['lastname'].hasError('maxlength')">All upper case is not allowed on last name.</mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 col-sm-6 col-lg-4">
                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">work company</mat-label>
                    <input *ngIf="!isEmployeeOnMyProfile && !isEmployeeOnManageUser && (!isContractorOrEmployeeJVPartnerOnMyProfile) || operationType === 'create'" matInput formControlName="workcompany">
                    <input *ngIf="(isEmployeeOnMyProfile || isEmployeeOnManageUser || isContractorOrEmployeeJVPartnerOnMyProfile) && operationType !== 'create'" matInput formControlName="workcompany" readonly [matTooltip]="isEmployeeOnMyProfile || isEmployeeOnManageUser ? 'This can not be managed from here, it must be managed in Workday.' : 'This is a read only field and can not be changed.'">
                    <mat-error *ngIf="userDetailsForm.controls['workcompany'].hasError('required')">This is a required field.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['workcompany'].hasError('pattern')">Work company code must consist of 4 numbers.</mat-error>

                </mat-form-field>
            </div>

        </div>

        <div class="row">

            <div class="col-12 col-sm-6 col-lg-8">
                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">job title</mat-label>
                    <input matInput formControlName="jobtitle">
                    <mat-error *ngIf="userDetailsForm.controls['jobtitle'].hasError('required')">This is a required field.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['jobtitle'].hasError('minlength')">Must be at least 2 characters.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['jobtitle'].hasError('maxlength')">Can not be longer than 40 characters.</mat-error>
                </mat-form-field>                     
            </div>            


            <div class="col-12 col-sm-6 col-lg-4" *ngIf="operationType === 'myProfile'">
                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">authorization code</mat-label>
                    <input matInput formControlName="authorizationCode"  [type]="hide? 'password':'text'" style="border: none;box-shadow: none;padding: 0px;">
                    <button mat-icon-button matSuffix (click)="passwordHide()" type="button">
                        <mat-icon>{{hide?'visibility_off':'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </div>

        </div>

        <div *ngIf="operationType === 'create'  || user.personType !== 'Employee'">

            <div class="row">

                <div class="col-12 col-sm-6 col-lg-3" *ngIf="operationType !== 'myProfile'">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">person type</mat-label>
                        <mat-select formControlName="personType">
                            <mat-option [value]="'JV Partner'" >JV Partner</mat-option>
                            <mat-option [value]="'Contractor'">Contractor</mat-option>
                        </mat-select>
                        <mat-error *ngIf="userDetailsForm.controls['personType'].hasError('required')">This is a required field.</mat-error>
                    </mat-form-field>  
                </div>

                <div class="col-12 col-sm-6 col-lg-3" *ngIf="userDetailsForm.controls['personType'].value === 'Contractor' && operationType !== 'myProfile'">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">contractor type</mat-label>
                        <mat-select formControlName="contractortype" >
                            <mat-option [value]="2">Contractor - Staff Aug</mat-option>
                            <mat-option [value]="3">Contractor - Mgd Services</mat-option>
                            <mat-option [value]="4">Contractor - JV Partner</mat-option>
                            <mat-option [value]="5">Contractor - Consultant</mat-option>
                        </mat-select>
                        <mat-error *ngIf="userDetailsForm.controls['contractortype'].hasError('required')">This is a required field.</mat-error>
                    </mat-form-field>    
                    <span class="fields-custom-error" *ngIf="!isValidNumberForContractorType() && operationType === 'edit'"> This is a required field. </span>
                </div>

                <div class="col-12 col-sm-6 col-lg-3" *ngIf="userDetailsForm.controls['personType'].value !== 'Employee' && operationType !== 'myProfile'">
                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">sourcing company</mat-label>
                    <input matInput formControlName="sourcingCompanyDisplay" readonly>
                    <button mat-icon-button matSuffix (click)="searchForCampEntity()" type="button" *ngIf="userDetailsForm.controls['sourcingCompanyDisplay'].value !== '' || operationType === 'create'" [disabled]="isReadonly">
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-spinner diameter="30" matSuffix *ngIf="userDetailsForm.controls['sourcingCompanyDisplay'].value === '' && operationType !== 'create'"></mat-spinner>
                    <mat-error>{{ getCampError() }}</mat-error>  
                </mat-form-field>   
                </div>
                <div class="col-12 col-sm-6 col-lg-3" *ngIf="checkFieldViewState('accountEndDate')">
                    <mat-form-field appearance="outline" class="w-100"> 
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">account end date</mat-label>
                        <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" formControlName="accountEndDate" (focus)="clearCustomError()" (blur)="validateDate()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker (closed)="validateDate()"></mat-datepicker>
                        <mat-error *ngIf="userDetailsForm.controls['accountEndDate'].hasError('matDatepickerMin')||userDetailsForm.controls['accountEndDate'].hasError('matDatepickerMax')">This is not a valid account end date.</mat-error>
                        <mat-error *ngIf="userDetailsForm.controls['accountEndDate'].hasError('required')">This is a required field.</mat-error>            
                    </mat-form-field>  
                    <span class="fields-custom-error" *ngIf="isInvalidAccountEndDate && !(userDetailsForm.controls['accountEndDate'].hasError('matDatepickerMin')||userDetailsForm.controls['accountEndDate'].hasError('matDatepickerMax'))"> Account end date should be 7 days greater than the current date. </span>
                </div>
            </div>
        </div>

        <mat-divider class="mb-4"></mat-divider>

        <div class="row">
            <div class="col-sm-4" >
                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">sponsor</mat-label>
                    <input matInput formControlName="sponsorDisplayName" readonly 
                        [matTooltip]="isReadOnlyWorkerField() ? 'This is a read only field and can not be changed.' : 'Please use the search icon, you can not enter these details manually.'">            
                    <button mat-icon-button matSuffix (click)="searchForPrimaryUser('sponsor')" type="button" *ngIf="userDetailsForm.controls['sponsorDisplayName'].value !== '' || operationType === 'create'" 
                        [disabled]="isReadOnlyWorkerField()">
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-spinner diameter="30" matSuffix *ngIf="userDetailsForm.controls['sponsorDisplayName'].value === '' && operationType !== 'create'"></mat-spinner>
                    <mat-error *ngIf="userDetailsForm.controls['sponsor'].value === 'invalid'">The sponsor currently specified is not an active user.</mat-error>  
                    <mat-error *ngIf="userDetailsForm.controls['sponsor'].value === 'self-assigned'">The sponsor cannot be the same as the user.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['sponsor'].value === 'duplicate'">The sponsor has a duplicate provisioning id.</mat-error>
                </mat-form-field>           
            </div>

            <div class="col-sm-4">
                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">supervisor</mat-label>
                    <input matInput *ngIf="!isEmployeeOnMyProfile" formControlName="supervisorDisplayName" readonly 
                        [matTooltip]="isReadOnlyWorkerField() ? 'This is a read only field and can not be changed.' : 'Please use the search icon, you can not enter these details manually.'">
                    <input matInput *ngIf="isEmployeeOnMyProfile" formControlName="supervisorDisplayName" readonly matTooltip="This can not be managed from here, it must be managed in Workday.">
                    <button mat-icon-button matSuffix (click)="searchForPrimaryUser('supervisor')" type="button" [disabled]="isEmployeeOnMyProfile || isReadOnlyWorkerField()" *ngIf="userDetailsForm.controls['supervisorDisplayName'].value !== ''  || operationType === 'create'">
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-spinner diameter="30" matSuffix *ngIf="userDetailsForm.controls['supervisorDisplayName'].value === '' && operationType !== 'create'"></mat-spinner>
                    <mat-error *ngIf="userDetailsForm.controls['supervisor'].value === 'invalid'">The supervisor currently specified is not an active user.</mat-error> 
                    <mat-error *ngIf="userDetailsForm.controls['supervisor'].value === 'self-assigned'">The supervisor cannot be the same as the user.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['supervisor'].value === 'duplicate'">The supervisor has a duplicate provisioning id.</mat-error>
                </mat-form-field>        
            </div>

            <div class="col-sm-4">
                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">approver</mat-label>
                    <input matInput formControlName="approverDisplayName" readonly 
                        [matTooltip]="isReadOnlyWorkerField() ? 'This is a read only field and can not be changed.' : 'Please use the search icon, you can not enter these details manually.'">
                    <button mat-icon-button matSuffix (click)="searchForPrimaryUser('approver')" type="button" *ngIf="userDetailsForm.controls['approverDisplayName'].value !== ''  || operationType === 'create'" [disabled]="isReadOnlyWorkerField()">
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-spinner diameter="30" matSuffix *ngIf="userDetailsForm.controls['approverDisplayName'].value === '' && operationType !== 'create'"></mat-spinner>
                    <mat-error *ngIf="userDetailsForm.controls['approver'].value === 'invalid'">The approver currently specified is not an active user.</mat-error>  
                    <mat-error *ngIf="userDetailsForm.controls['approver'].value === 'self-assigned'">The approver cannot be the same as the user.</mat-error>
                    <mat-error *ngIf="userDetailsForm.controls['approver'].value === 'duplicate'">The approver has a duplicate provisioning id.</mat-error>
                </mat-form-field>        
            </div>
        </div>

        <div class="form-row p-2 pl-3 mb-4 mt-4 bg-dark-blue text-white"><p><strong>phone & address information</strong></p></div>

        <div *ngIf="operationType === 'myProfile'" class="form-row p-2 mb-4 bg-light">
            <p>
                Information provided in this section will be synchronized to all connected interfaces and visible in systems like Teams and Outlook.
            </p>
        </div>

        <div class="row">
            <div class="col-4">

                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">ctn</mat-label>
                    <input matInput formControlName="ctnNumber" class="pb-1" pattern="([0-9]{3}-[0-9]{4}||[0-9]{8})">
                    <mat-error>Invalid CTN format, valid format: 123-4567 or 12345678</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">alt ctn</mat-label>
                    <input matInput formControlName="ctnNumberAlt" class="pb-1" pattern="([0-9]{3}-[0-9]{4}||[0-9]{8})">
                    <mat-error>Invalid CTN format, valid format: 123-4567 or 12345678</mat-error>
                </mat-form-field>

            </div>

            <div class="col-4">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">company mobile</mat-label>
                    <ngx-mat-intl-tel-input formControlName="mobileNumber" class="no-brand"
                        [enablePlaceholder]="true"
                        [enableSearch]="true"
                        name="mobileNumber"
                    ></ngx-mat-intl-tel-input>
                    <mat-error *ngIf="userDetailsForm.controls['mobileNumber'].errors?.validatePhoneNumber">Invalid Number</mat-error>
                </mat-form-field>
            </div>

            <div class="col-4">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">long distance</mat-label>
                    <ngx-mat-intl-tel-input formControlName="telephoneNumber"
                        [enablePlaceholder]="true"
                        [enableSearch]="true"
                        name="telephoneNumber"
                    ></ngx-mat-intl-tel-input>
                    <mat-error *ngIf="userDetailsForm.controls['telephoneNumber'].errors?.validatePhoneNumber">Invalid Number</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">alt long distance</mat-label>
                    <ngx-mat-intl-tel-input formControlName="telephoneNumberAlt"
                        [enablePlaceholder]="true"
                        [enableSearch]="true"
                        name="telephoneNumberAlt"                        
                    ></ngx-mat-intl-tel-input>
                    <mat-error *ngIf="userDetailsForm.controls['telephoneNumberAlt'].errors?.validatePhoneNumber">Invalid Number</mat-error>
                </mat-form-field>            
            </div>
        </div>                        

        <mat-divider class="mb-4"></mat-divider>

        <div class="row">
            <div class="col-sm-8">
                
                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">location</mat-label>
                    <input matInput *ngIf="!isEmployeeOnMyProfile && !isEmployeeOnManageUser" formControlName="locationDisplayName" readonly matTooltip="Please use the search icon, you can not enter these details manually.">
                    <input matInput *ngIf="isEmployeeOnMyProfile || isEmployeeOnManageUser" formControlName="locationDisplayName" readonly matTooltip="This can not be managed from here, it must be managed in Workday.">
                    <button mat-icon-button matSuffix (click)="searchForLocation()" type="button" [disabled]="isEmployeeOnMyProfile || isEmployeeOnManageUser" *ngIf="userDetailsForm.controls['locationDisplayName'].value !== '' || operationType === 'create'">
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-spinner diameter="30" matSuffix *ngIf="userDetailsForm.controls['locationDisplayName'].value === '' && operationType !== 'create'"></mat-spinner>
                    <mat-error>{{ getLocationError() }}</mat-error>  
                </mat-form-field>
            
            </div>

            <div class="col-sm-4">

                <mat-form-field appearance="outline" class="w-100"> 
                    <mat-label class="font-gotham-narrow-bold text-dark-blue">room</mat-label>
                    <input matInput formControlName="locationRoom">
                </mat-form-field>


            </div>
        </div>

        <div *ngIf="operationType === 'myProfile'">
            <div class="form-row p-2 pl-3 mb-4 mt-4 bg-dark-blue text-white"><p><strong>emergency notification information</strong></p></div>

            <div class="form-row p-2 mb-4 bg-light">
                    
                <p>
                    You may choose to voluntarily provide additional personal contact information for the company to send you life-safety, security, crisis management, 
                    business continuity and other emergency communications during or following an emergency event.
                </p>

                <p>
                    The personal contact information you provide below will not be visible to others using this portal. Please revisit this page if at anytime you wish to update or delete this contact information.
                </p>

                <p>
                    Any charges to receive messages on these devices will not be reimbursed by Chevron.
                </p>

            </div>
            
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <mat-form-field appearance="outline" class="w-100"> 
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">personal home number</mat-label>
                        <ngx-mat-intl-tel-input formControlName="personalTelephoneNumber" class="no-brand"
                        [enablePlaceholder]="true"
                        [enableSearch]="true"
                        name="mobileNumber"
                        ></ngx-mat-intl-tel-input>
                        <mat-error *ngIf="userDetailsForm.controls['personalTelephoneNumber'].errors?.validatePhoneNumber">Invalid Number</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <mat-form-field appearance="outline" class="w-100"> 
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">personal mobile number</mat-label>
                        <ngx-mat-intl-tel-input formControlName="personalTelephoneNumberAlt" class="no-brand"
                        [enablePlaceholder]="true"
                        [enableSearch]="true"
                        name="mobileNumber"
                        ></ngx-mat-intl-tel-input>
                        <mat-error *ngIf="userDetailsForm.controls['personalTelephoneNumberAlt'].errors?.validatePhoneNumber">Invalid Number</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <mat-form-field appearance="outline" class="w-100"> 
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">personal email</mat-label>
                        <input matInput formControlName="personalEmail">
                        <mat-error *ngIf="userDetailsForm.controls['personalEmail'].hasError('email')">Invalid email address format</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <p class="text-danger text-center" *ngIf="errorMessageEmergencyContactDetails.length !== 0">{{errorMessageEmergencyContactDetails}}</p>
            <p class="text-success text-center" *ngIf="screenMessageEmergencyContactDetails.length !== 0">{{screenMessageEmergencyContactDetails}}</p>

        </div>

        <div *ngIf="operationType !== 'create'"> 
            <div class="form-row p-2 pl-3 mb-4 mt-4 bg-dark-blue text-white"><p><strong>hr information</strong></p></div>

            <div class="form-row p-2 mb-4 bg-light w-100 text-center">
            <p>The information displayed here may only be updated in Workday. It can not be updated from this portal.</p>
            </div>

            <fieldset [disabled]="true">
                <legend hidden>HR information</legend>
                <div class="row">
                    <div class="col-12 col-lg-6">
                
                        <mat-form-field appearance="outline" class="w-100"> 
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">name</mat-label>
                            <input matInput formControlName="hrName">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100"> 


                            <mat-label class="font-gotham-narrow-bold text-dark-blue">job profile</mat-label>

                            <input matInput formControlName="hrJobTitle">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100"> 
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">billing company</mat-label>
                            <input matInput formControlName="hrBillingCompany">
                        </mat-form-field>

                        <mat-form-field *ngIf="!(isContractorOrEmployeeJVPartnerOnManageUser || isContractorOrEmployeeJVPartnerOnMyProfile || isContractorOrEmployeeJVPartnerOnSearchUser)" appearance="outline" class="w-100"> 
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">cost center</mat-label>
                            <input matInput formControlName="hrCostCenter">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100"> 
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">local person home id</mat-label>
                            <input matInput formControlName="hrPersonnelNumber">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100"> 
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">hr guid</mat-label>
                            <input matInput formControlName="hrGUID">
                        </mat-form-field>

                    </div>

                    <div class="col-12 col-sm-6">
                
                        <mat-form-field appearance="outline" class="w-100" > 
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">Org level 1</mat-label>
                            <input matInput formControlName="hrLevel1LongDescription">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100"> 
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">Org level 2</mat-label>
                            <input matInput formControlName="hrLevel2LongDescription">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100"> 
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">Org level 3</mat-label>
                            <input matInput formControlName="hrLevel3LongDescription">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100"> 
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">Org level 4</mat-label>
                            <input matInput formControlName="hrLevel4LongDescription">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100"> 
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">Org level 5</mat-label>
                            <input matInput formControlName="hrLevel5LongDescription">
                        </mat-form-field>

                    </div>
                </div> 
            </fieldset>
        </div>
    </fieldset>  

    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
        <div class="btn-group mr-2" role="group">
            <mat-spinner diameter="40" *ngIf="isUpdating"></mat-spinner>
        </div>
        <div class="btn-group mr-2" role="group" *ngIf="operationType === 'create'">
            <button class="button" type="submit" (click)="createUser()" *ngIf="!isUpdating">create</button>
        </div>
        <div class="btn-group mr-2" role="group" *ngIf="operationType === 'edit' || operationType === 'myProfile'">
            <button class="button" type="submit" (click)="updateUser()" *ngIf="(!isUpdating && operationType === 'myProfile') || (operationType === 'edit' && !isUpdating && userIsActive)" [disabled]="operationType === 'edit' && !isAllowedToManageUser">update</button>
        </div>
        <ng-container *ngIf="operationType === 'edit'">
            <div class="btn-group mr-2" role="group" *ngIf="!userIsActive">
                <button [ngClass]="isEmployeeOnReactivateUser ? 'button button-disabled' : 'button'"
                    type="submit" (click)="reactivateUser()" *ngIf="!isUpdating && !isEmployeeOnReactivateUser" [disabled]="operationType === 'edit' && !isAllowedToManageUser">reactivate</button>
            </div>
            <div class="btn-group mr-2" role="group" *ngIf="userIsActive">
                <button [ngClass]="isEmployeeOnDeactivateUser ? 'button button-disabled' : 'button'"
                    type="submit" (click)="deactivateUser()" *ngIf="!isUpdating && !isEmployeeOnDeactivateUser" [disabled]="operationType === 'edit' && !isAllowedToManageUser">deactivate</button>
            </div>
        </ng-container>
        <div class="btn-group mr-2" role="group">
            <button class="button" type="button" (click)="resetForm()">cancel</button>                                 
        </div>
    </div>  

    <p class="text-danger text-center" *ngIf="errorMessage.length !== 0">{{errorMessage}}</p>
    <p class="text-success text-center" *ngIf="screenMessage.length !== 0">{{screenMessage}}</p>

</form>

