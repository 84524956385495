import { IIdmApplication } from "../models/idm/applications/IdmApplication";

export class ToStringHelper {
    static hasDisplayName(obj: any): boolean {
        return typeof obj == 'object' && 'displayName' in obj;
    }

    static isIChevronApplication(obj: any): obj is IIdmApplication {
        return typeof obj == 'object' && (obj as IIdmApplication).serviceId !== undefined && (obj as IIdmApplication).displayName !== undefined;
    }
    static overrideToString<T>(obj: T): T {

        if (ToStringHelper.isIChevronApplication(obj)) {
            let o = obj as IIdmApplication;
            o.toString = () => `${o.serviceId}${o.displayName ? `: ${o.displayName}` : ''}`;
            return o as T;
        }
        else if (ToStringHelper.hasDisplayName(obj)) {
            let o = obj as any;
            o.toString = () => `${o.displayName}`;
            return o as T;
        }
        else {
            let o = obj as object;
            o.toString = () => `${obj}`;
            return o as T;
        }
    }
}