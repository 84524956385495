import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DirectoryGroupCreateRequest } from 'src/app/models/directory/groups/DirectoryGroupCreateRequest';

@Component({
  selector: 'request-details-group-create',
  templateUrl: './request-details-group-create.component.html'
})

export class RequestDetailsGroupCreate implements OnInit {
  @Input() requestData: DirectoryGroupCreateRequest;
  @Output() doneLoading = new EventEmitter<boolean>();

  displayColumns: string[] = ['displayName', 'type', 'serviceId', 'dataProtectionClassification'];

  ngOnInit() {
    this.doneLoading.emit(true);
  }
}
