import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { UserType } from "src/app/models/directory/enums/UserType.enum";
import { IGraphUser } from "src/app/models/graphUser";
import { DialogDirectorySearchComponent } from "../../_shared/dialog-directory-search/dialog-directory-search.component";
import { IDialogDirectoryData } from "src/app/models/components/IDialogDirectoryData";
import { lastValueFrom } from "rxjs";
import { DirectoryGroupService } from "src/app/services/directory/DirectoryGroup.service";
import { DirectoryGroupCreateRequest } from "src/app/models/directory/groups/DirectoryGroupCreateRequest";
import { DirectoryGroupType } from "src/app/models/directory/enums/DirectoryGroupType";
import { DirectoryGroup } from "src/app/models/directory/groups/DirectoryGroup";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { ValidationPatternConstants } from "src/app/constants/ValidationPattern.constants";
import { DataProtectionClassification } from "src/app/models/directory/enums/DataProtectionClassification.enum";
import { IIdmApplication } from "src/app/models/idm/applications/IdmApplication";
import { DialogAppSearchChevronComponent } from "../../_shared/dialog-app-search-chevron/dialog-app-search-chevron.component";
import { IDialogAppSearchChevronData } from "src/app/models/idm/applications/IDialogAppSearchChevronData";
import { ToStringHelper } from "src/app/helpers/ToStringHelper";

@Component({
    selector: 'app-create-group',
    templateUrl: './create-group.component.html',
    styleUrls: ['./create-group.component.css']
})
export class CreateGroupComponent implements OnInit {

    PageLayout = PageLayout;
    currentUserProfile: ICvxClaimsPrincipal;
    isCreating: boolean = false;
    createdGroup: IGenericApiResponseWithWorkflowRequest<DirectoryGroup> | undefined;
    displayCreatedGroup: any;
    createErrorMessage = { message: '', errors: [] };

    selected = DirectoryGroupType.Security;

    nameValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const typeControl = control.get('type');
        const nameControl = control.get('name');

        if (typeControl && nameControl && typeControl.value === DirectoryGroupType.Security) {
            if (nameControl.value.length > 256) {
                nameControl.setErrors({
                    maxlength: { requiredLength: 256 }
                })
            }
        }

        return null;
    };

    groupForm = this.formBuilder.group({
        type: [this.selected, Validators.required],
        name: ['', [Validators.maxLength(256), Validators.required, Validators.pattern(ValidationPatternConstants.NamePattern)]],
        owners: this.formBuilder.array([], Validators.minLength(2)),
        description: ['', [Validators.maxLength(256), Validators.required]],
        chevronApp: [null as IIdmApplication | null],
        dataProtectionClassification: [null as DataProtectionClassification | null, Validators.required],
    }, { validators: this.nameValidator });

    get chevronApp() { return this.groupForm.get('chevronApp')?.value?.displayName ?? ''; }

    get owners() { return this.groupForm.get('owners') as FormArray; }

    get groupTypes() { return Object.values(DirectoryGroupType) };

    get dataProtectionClassifications() { return Object.values(DataProtectionClassification) };

    get groupFormValues() {
        let values = this.groupForm.value;
        let payload = new DirectoryGroupCreateRequest(
            values.name!,
            this.owners.value.map((item: IGraphUser) => item.id),
            DirectoryGroupType[values.type as keyof typeof DirectoryGroupType],
            values.description!,
            values.chevronApp?.serviceId,
            values.dataProtectionClassification as DataProtectionClassification);
        return payload;
    }

    constructor(
        private authService: CalAngularService,
        private dialog: MatDialog,
        private directoryGroupService: DirectoryGroupService,
        private formBuilder: FormBuilder
    ) { }

    async ngOnInit(): Promise<void> {
        await this.addCurrentUserAsOwner();
    }

    // default add the current user as an owner
    async addCurrentUserAsOwner() {
        if (await lastValueFrom(this.authService.isUserSignedIn())) {
            this.currentUserProfile = this.authService.cvxClaimsPrincipal;
            this.addOwner(this.currentUserProfile.objectId, this.currentUserProfile.name, this.currentUserProfile.email);
        }
    }

    addOwner(id: string, name: string, email: string) {
        this.owners.push(this.formBuilder.group({
            id: [id],
            displayName: [name],
            mail: [email]
        }));
    }

    searchForPrimaryUser(): void {
        const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
            disableClose: true,
            autoFocus: true,
            maxWidth: 1000,
            width: '100%',
            data: {
                type: GroupMemberType.User,
                domain: DirectoryDomain.Chevron,
                userType: UserType.Primary,
                filterGroupRemoveNotAvailableAsGroupMember: false,
                filterGroupRemoveDynamicMembershipEnabled: false,
                filterGroupOnlyManagedByIdamp: false
            } as IDialogDirectoryData
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.addOwner(result.id, result.displayName, result.mail);
            }
        });
    }

    removeOwner(i: number) {
        this.owners.removeAt(i);
    }

    async onSubmit() {
        // clear error messages
        this.createErrorMessage.message = '';
        this.createErrorMessage.errors = [];

        if (this.groupForm.valid) {

            this.isCreating = true;
            let payload = this.groupFormValues;

            const observer = {
                next: (x: IGenericApiResponseWithWorkflowRequest<DirectoryGroup>) => {
                    this.createdGroup = x;
                },
                error: (err: any) => {
                    this.createErrorMessage.message = err.statusText;
                    this.createErrorMessage.errors = err?.error?.errors ?? [];
                    this.isCreating = false;
                },
                complete: () => {
                    this.isCreating = false;
                }
            };

            let createCall = this.directoryGroupService.CreateGroup(DirectoryDomain.Chevron, payload);
            createCall.subscribe(observer);
        }
        else {
            // form is invalid, trigger all validation fields
            this.groupForm.markAllAsTouched();
        }
    }
    async resetForm() {
        this.groupForm.reset();
        this.groupForm.controls.owners.clear();
        await this.addCurrentUserAsOwner();
    }

    searchForChevronApplication(): void {
        const appDialogRef = this.dialog.open(DialogAppSearchChevronComponent, {
            disableClose: true,
            autoFocus: true,
            maxWidth: 1000,
            width: '100%',
            data: {
                includeEnvironments: false
            } as IDialogAppSearchChevronData
        });
        appDialogRef.afterClosed().subscribe(result => {
            if (result) {
                var chevronApp = result as IIdmApplication;

                // override toString method to display displayName (as form control value will display [object Object] without this)
                ToStringHelper.overrideToString(chevronApp);
                this.groupForm.patchValue({ chevronApp: chevronApp });
            }
        });
    }

}

