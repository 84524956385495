import { Component } from '@angular/core';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';
import { RouteConstants } from 'src/app/models/common/enums/RouteConstants.enum';

@Component({
  selector: 'app-update-service-account',
  templateUrl: './update-service-account.component.html',
})
export class UpdateServiceAccountComponent {
  userType: UserType = UserType.Service;
  displayedColumns: string[] = ['userPrincipalName', 'type', 'domain', 'applicationId'];
  route: string = RouteConstants.ServicePrivilege;
}
