<np-alert>
    <p>Please note, creation of an identity or CAI will have associated cost and compliance requirements.</p>
</np-alert>
<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row">
            <div class="col col-12">
                <h1>create primary user</h1>

                <app-primary-user-details [operationType]="'create'" *ngIf="IsAuthorised"></app-primary-user-details>

                <div class="container" *ngIf="!IsAuthorised ">
                    <div class="row justify-content-center col-sm-12">
                        <p>You are not authorised to create users.</p>                               
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</main>
