<div class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white" *ngIf="showHeader">
    <p>
        <strong>{{ headerText }}</strong>
    </p>
</div>

<h4 id="title" *ngIf="showTitle"><span *ngIf="showCounter">{{ records.length }}</span> {{ title }}</h4>

<div *ngIf="allowLocalFilter" [class]="makeSticky ? 'stickyHeader' : ''">
    <filter-input (onKeyUp)="doFilter($event)"></filter-input>
</div>

<table [class]="makeSticky ? 'stickyTableHeaders' : ''" aria-describedby="title" mat-table [dataSource]="dataSource"
    *ngIf="!sortable">
    <caption>{{ caption }}</caption>

    <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
        <th mat-header-cell *matHeaderCellDef>{{disCol | convertDisplayLabels}}</th>
        <td mat-cell *matCellDef="let element; let rowIndex = index"
            [style.cursor]="selectableRecords ? 'pointer' : 'default'">            
            <text-formatter *ngIf="disCol != 'copyId'" [content]="element[disCol]"></text-formatter>
            <div [cdkCopyToClipboard]="element[disCol]" (cdkCopyToClipboardCopied)="onClipboardCopy($event, rowIndex)"
                (mouseleave)="resetClipBoardStatus()" style="text-align:center;" *ngIf="disCol == 'copyId'"><button
                    class="button small icon no-arrow"
                    [style]="copiedToClipboardId == rowIndex ? 'background-color: #769231;' : ''"><mat-icon>content_copy</mat-icon></button>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="del" *ngIf="deletableRecords">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="text-align:center;">
            <button mat-icon-button matSuffix (click)="deleteRow(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="cols"></tr>
    <tr mat-row (click)="selectRow(row)" *matRowDef="let row; columns: cols;"></tr>

</table>

<table [class]="makeSticky ? 'stickyTableHeaders' : ''" aria-describedby="title" mat-table [dataSource]="dataSource"
    matSort *ngIf="sortable">

    <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{disCol | convertDisplayLabels}}</th>
        <td mat-cell *matCellDef="let element" [style.cursor]="selectableRecords ? 'pointer' : 'default'">
            <text-formatter [content]="element[disCol]"></text-formatter>
        </td>
    </ng-container>

    <ng-container matColumnDef="del" *ngIf="deletableRecords">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" style="text-align:center;">
            <button mat-icon-button matSuffix (click)="deleteRow(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="cols"></tr>
    <tr mat-row (click)="selectRow(row)" *matRowDef="let row; columns: cols;"></tr>

</table>

<mat-paginator *ngIf="showPagination" [pageSizeOptions]="[10, 20, 30, 40]" showFirstLastButtons></mat-paginator>