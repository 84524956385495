import { Pipe, PipeTransform } from '@angular/core';
import { DataProtectionClassification, DataProtectionClassificationDescriptions } from '../models/directory/enums/DataProtectionClassification.enum';

@Pipe({
  name: 'mapDataProtectionToDescription',
  standalone: true
})
export class MapDataProtectionToDescription implements PipeTransform {

  transform(value: DataProtectionClassification): string {
    let description = DataProtectionClassificationDescriptions[value];
    if (description == null) {
      description = DataProtectionClassificationDescriptions[DataProtectionClassification.Tier1];
    }
    return description!;
  }
}
