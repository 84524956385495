import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { lastValueFrom } from 'rxjs';
import { DialogDirectorySearchComponent } from 'src/app/components/_shared/dialog-directory-search/dialog-directory-search.component';
import { ValidationPatternConstants } from 'src/app/constants/ValidationPattern.constants';
import { ToStringHelper } from 'src/app/helpers/ToStringHelper';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { IDialogDirectoryData } from 'src/app/models/components/IDialogDirectoryData';
import { DirectoryDomain } from 'src/app/models/directory/enums/DirectoryDomain.enum';
import { GroupMemberType } from 'src/app/models/directory/enums/GroupMemberType.enum';
import { MailDomain } from 'src/app/models/directory/enums/MailDomain.enum';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';
import { DirectoryMailboxUserCreateRequest } from 'src/app/models/directory/users/DirectoryMailboxUserCreateRequest';
import { DirectoryUser } from 'src/app/models/directory/users/DirectoryUser';
import { DirectoryUserService } from 'src/app/services/directory/DirectoryUser.service';

@Component({
  selector: 'app-create-mailbox-account',
  templateUrl: './create-mailbox-account.component.html',
  styleUrl: './create-mailbox-account.component.css'
})
export class CreateMailboxAccountComponent implements OnInit {
  PageLayout = PageLayout;
  currentUserProfile: ICvxClaimsPrincipal;
  isCreating: boolean = false;
  createdMailbox: IGenericApiResponseWithWorkflowRequest<DirectoryUser> | undefined;
  displayCreatedUser: any;
  createErrorMessage = { message: '', errors: [] };

  stewardErrorMessage: string = '';
  delegateErrorMessage: string = '';

  chainOfCommandErrorMessage: string = '';


  mailboxForm = this.formBuilder.group({
    displayName: ['', Validators.compose([
      Validators.required,
      Validators.maxLength(64),
      Validators.pattern(ValidationPatternConstants.NamePattern),
    ]),
    ],
    businessPurpose: ['', Validators.compose([
      Validators.required,
      Validators.maxLength(256)
    ]),
    ],
    mailNickname: ['',
      Validators.compose([
        Validators.required,
        Validators.maxLength(64),
        Validators.pattern(ValidationPatternConstants.MailNicknamePattern),
      ]),
    ],
    mailDomain: ['', Validators.required],
    steward: [null as DirectoryUser | null, Validators.required],
    delegate: [null as DirectoryUser | null, Validators.required],
    chainOfCommands: this.formBuilder.array([] as DirectoryUser[], Validators.minLength(1)),
  });

  get steward() { return this.mailboxForm.get('steward')?.value?.displayName ?? ''; }

  get delegate() { return this.mailboxForm.get('delegate')?.value?.displayName ?? ''; }

  get chainOfCommands() { return this.mailboxForm.get('chainOfCommands') as FormArray; }

  get mailDomains() { return Object.values(MailDomain) };

  get mailboxFormValues() {
    const values = this.mailboxForm.value;
    const stewardProvisioningId = values.steward!.provisioningId;
    const delegateProvisioningId = values.delegate!.provisioningId;
    const chainOfCommands = values.chainOfCommands as DirectoryUser[];

    let payload = new DirectoryMailboxUserCreateRequest(
      values.displayName!,
      values.businessPurpose!,
      values.mailNickname!,
      values.mailDomain as MailDomain,
      stewardProvisioningId,
      delegateProvisioningId,
      chainOfCommands.map((x: DirectoryUser | null) => `${x!.provisioningId}`) ?? []
    );

    return payload;
  }

  constructor(
    private authService: CalAngularService,
    private dialog: MatDialog,
    private directoryUserService: DirectoryUserService,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit(): Promise<void> {
    await this.addCurrentUserAsSteward();
  }

  // default add the current user as an owner
  async addCurrentUserAsSteward() {
    if (await lastValueFrom(this.authService.isUserSignedIn())) {
      this.currentUserProfile = this.authService.cvxClaimsPrincipal;
      let currentUser = {
        id: this.currentUserProfile.objectId,
        displayName: this.currentUserProfile.name,
        mail: this.currentUserProfile.email,
        provisioningId: this.currentUserProfile.provisioningId,
      } as DirectoryUser;

      // override toString method to display displayName (as form control value will display [object Object] without this)
      this.addUserToRole('steward', ToStringHelper.overrideToString(currentUser));
    }
  }

  addUserToRole(role: string, user: DirectoryUser) {

    // override toString method to display displayName (as form control value will display [object Object] without this)
    user = ToStringHelper.overrideToString(user);

    // clear error messages
    this.clearRoleErrorMessages();

    // check if user is already in the list
    let listOfUser: string[] = []
    this.chainOfCommands.value.map((element: DirectoryUser) => {
      listOfUser.push(element.id)
    });

    const stewardId = this.mailboxForm.get('steward')?.value?.id;
    if (stewardId) listOfUser.push(stewardId);

    const delegateId = this.mailboxForm.get('delegate')?.value?.id;
    if (delegateId) listOfUser.push(delegateId);


    let isInList = listOfUser.findIndex(o => o == user.id) > -1;

    if (!isInList) {
      switch (role) {
        case 'steward':
          this.mailboxForm.patchValue({ steward: user });
          break;
        case 'delegate':
          this.mailboxForm.patchValue({ delegate: user });
          break;
        case 'chainOfCommand':
          if (this.chainOfCommands.length < 3) {
            this.chainOfCommands.push(this.formBuilder.group(user));
          }
          else {
            this.chainOfCommandErrorMessage = "You can only have 3 users in the chain of command.";
          }
          break;
      }
    }
    else {
      // user is already in the list
      // set error message for the role
      const errorMessage = 'User is already assigned a different role';
      switch (role) {
        case 'steward':
          if (user.id !== stewardId) this.stewardErrorMessage = errorMessage;
          break;
        case 'delegate':
          if (user.id !== delegateId) this.delegateErrorMessage = errorMessage;
          break;
        case 'chainOfCommand':
          this.chainOfCommandErrorMessage = errorMessage;
          break;
      }
    }
  }

  searchForPrimaryUser(role: string): void {
    const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%',
      data: {
        type: GroupMemberType.User,
        domain: DirectoryDomain.Chevron,
        userType: UserType.Primary,
        filterGroupRemoveNotAvailableAsGroupMember: false,
        filterGroupRemoveDynamicMembershipEnabled: false,
        filterGroupOnlyManagedByIdamp: false
      } as IDialogDirectoryData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addUserToRole(role, result);
      }
    });
  }

  removeChainOfCommand(i: number) {
    this.chainOfCommands.removeAt(i);
  }

  async onSubmit() {


    // clear error messages
    this.clearRoleErrorMessages();
    this.createErrorMessage.message = '';
    this.createErrorMessage.errors = [];

    if (this.mailboxForm.valid && this.chainOfCommands.length >= 1) {

      this.isCreating = true;
      let payload = this.mailboxFormValues;


      const observer = {
        next: (x: IGenericApiResponseWithWorkflowRequest<DirectoryUser>) => {
          this.createdMailbox = x;
        },
        error: (err: any) => {
          this.createErrorMessage.message = err.statusText;
          this.createErrorMessage.errors = err?.error?.errors ?? [];
          this.isCreating = false;
        },
        complete: () => {
          this.isCreating = false;
        }
      };

      let createCall = this.directoryUserService.CreateMailboxUser(DirectoryDomain.Chevron, payload);
      createCall.subscribe(observer);
    }
    else {
      // form is invalid, trigger all validation fields
      this.mailboxForm.markAllAsTouched();
      if (this.chainOfCommands.length < 1) {
        this.chainOfCommandErrorMessage = 'Chain of Command is required';
      }
    }
  }

  clearRoleErrorMessages() {
    this.stewardErrorMessage = '';
    this.delegateErrorMessage = '';
    this.chainOfCommandErrorMessage = '';
  }
  async resetForm() {
    this.mailboxForm.reset();
    this.chainOfCommands.clear();
    this.clearRoleErrorMessages();
    this.addCurrentUserAsSteward();
  }

  closeRequest() {
    this.createdMailbox = undefined;
  }

}
