import { DataProtectionClassification } from "../enums/DataProtectionClassification.enum";
import { DirectoryGroupType } from "../enums/DirectoryGroupType";
export type serviceIdType = string | null | undefined;
export class DirectoryGroupCreateRequest {

    constructor(displayName: string, owners: string[], type: DirectoryGroupType, description: string, serviceId: serviceIdType, dataProtectionClassification: DataProtectionClassification = DataProtectionClassification.Tier3) {
        this.groups = [new DirectoryGroupRequestItem(displayName, owners, type, description, serviceId, dataProtectionClassification)];
    }

    groups: DirectoryGroupRequestItem[] = [];
}

export class DirectoryGroupRequestItem {

    constructor(displayName: string, owners: string[], type: DirectoryGroupType, description: string, serviceId: serviceIdType, dataProtectionClassification: DataProtectionClassification = DataProtectionClassification.Tier3) {
        this.displayName = displayName;
        this.owners = owners;
        this.type = type;
        this.description = description;
        this.serviceId = serviceId;
        this.dataProtectionClassification = dataProtectionClassification ?? DataProtectionClassification.Tier3;
    }

    displayName: string = '';
    owners: string[];
    type: DirectoryGroupType;
    description: string = '';
    serviceId: serviceIdType;
    dataProtectionClassification: DataProtectionClassification;
    addMembers: DirectoryGroupMemberRequest[] = [];
}

export class DirectoryGroupMemberRequest {
    constructor(id: string, type: string, domain: string) {
        this.id = id;
        this.type = type;
        this.domain = domain;
    }

    id: string;
    type: string;
    domain: string;
}