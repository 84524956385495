import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { DirectoryObjectBase } from "src/app/models/directory/DirectoryObjectBase";
import { DirectoryGroupService } from "src/app/services/directory/DirectoryGroup.service";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { UserType } from "src/app/models/directory/enums/UserType.enum";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { IGraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { IDirectoryGroupFilter } from "src/app/models/services/IDirectoryGroupFilter";
import { AadGroupSearchScenario } from "src/app/models/directory/enums/AadGroupSearchScenario.enum";

@Component({
    selector: 'directory-search',
    templateUrl: './directory-search.component.html',
    styleUrls: ['./directory-search.component.css']
})

export class DirectorySearchComponent implements OnInit {

    @Input() isDialog: boolean = false;
    @Input() type: GroupMemberType;
    @Input() domain: DirectoryDomain;
    @Input() userType?: UserType;

    // these input variables are only considered when type === 'group'
    @Input() groupOwned: boolean = false;
    @Input() groupLoadMembers: boolean = false;
    @Input() groupMemberLimit: number = 1000;
    @Input() groupLoadOwners: boolean = false;

    @Input() aadGroupSearchScenario?: AadGroupSearchScenario;
    @Input() filterGroupRemoveNotAvailableAsGroupMember: boolean = false;
    @Input() filterGroupRemoveDynamicMembershipEnabled: boolean = false;
    @Input() filterGroupRemoveNonAzureGroups: boolean = false;

    @Input() filterGroupOnlyManagedByIdamp: boolean = false;

    @Input() filterGroupOnlyOnPremGroups: boolean = false;

    @Input() makeSticky: boolean = false;

    @Output() objectSelected = new EventEmitter<DirectoryObjectBase>();

    isSearching: boolean = false;
    screenMessage: string = '';

    searchTerm = new FormControl<string>('');
    searchType = new FormControl<GroupMemberType>(GroupMemberType.User);
    searchDomain = new FormControl<DirectoryDomain>(DirectoryDomain.Chevron);
    searchUserType = new FormControl<UserType>(UserType.Primary);
    searchTypeOptions: GroupMemberType[] = Object.values(GroupMemberType);
    searchUserTypeOptions: UserType[] = Object.values(UserType);
    searchDomainOptions: DirectoryDomain[] = Object.values(DirectoryDomain);
    searchResults: DirectoryObjectBase[] = [];

    displayColumns: string[] = ['displayName', 'domain', 'directoryObjectType'];

    /**
     * Whether this is a manual search with fields or just load based on input
     */
    get manualSearch(): boolean {
        return !(this.type === GroupMemberType.Group && this.groupOwned);
    }

    get showSearchFields(): boolean {
        return this.manualSearch;
    }

    get showUserTypeSearchField(): boolean {
        return this.searchType.value === GroupMemberType.User;
    }

    get showSearchButton(): boolean {
        return this.manualSearch && this.searchResults.length === 0 && !this.isSearching;
    }

    get showResetButton(): boolean {
        return this.manualSearch;
    }

    get showCloseButton(): boolean {
        return this.isDialog;
    }

    constructor(
        private directoryUserService: DirectoryUserService,
        private directoryGroupService: DirectoryGroupService,
        private directoryAppService: GraphApplicationService) {
    }

    async ngOnInit(): Promise<void> {
        if (this.type) {
            this.searchType.setValue(this.type);
            this.searchTypeOptions = [this.type];
            this.searchType.disable();
        }

        if (this.domain) {
            this.searchDomain.setValue(this.domain);
            this.searchDomainOptions = [this.domain];
            this.searchDomain.disable();
        }

        if (this.userType) {
            this.searchUserType.setValue(this.userType);
            this.searchUserTypeOptions = [this.userType];
            this.searchUserType.disable();
        }

        if (this.type === GroupMemberType.Group && this.groupOwned) {
            this.isSearching = true;
            this.directoryGroupService.GetOwnedGroups(
                undefined,
                {
                    removeNotAvailableAsGroupMember: this.filterGroupRemoveNotAvailableAsGroupMember,
                    removeDynamicMembershipEnabled: this.filterGroupRemoveDynamicMembershipEnabled,
                    filterGroupRemoveNonAzureGroups: this.filterGroupRemoveNonAzureGroups,
                    onlyOnPremGroups: this.filterGroupOnlyOnPremGroups,
                    onlyManagedByIdamp: this.filterGroupOnlyManagedByIdamp
                } as IDirectoryGroupFilter
            ).subscribe(this.searchObserver);
        }
    }

    searchObserver = {
        next: (x: IGenericApiResponse<DirectoryObjectBase[]>) => {
            this.searchResults = x.data;
        },
        error: (err: any) => {
            this.screenMessage = err.statusText;
            this.isSearching = false;
        },
        complete: () => {
            this.screenMessage = this.searchResults.length === 0 ? 'no results found' : '';
            this.isSearching = false;
        }
    };

    servicePrincipalObserver = {
        next: (x: IGenericApiResponse<IGraphApplication[]>) => {
            // convert to DirectoryBaseObjects as the service principal information
            this.searchResults = x.data.map((app: IGraphApplication) => {
                return {
                    id: app.id,
                    displayName: app.displayName,
                    domain: DirectoryDomain.Chevron,
                    directoryObjectType: GroupMemberType.ServicePrincipal
                } as DirectoryObjectBase;
            });


        },
        error: (err: any) => {
            this.screenMessage = err.statusText;
            this.isSearching = false;
        },
        complete: () => {
            this.screenMessage = this.searchResults.length === 0 ? 'no results found' : '';
            this.isSearching = false;
        }
    };

    async search() {
        if (this.searchTerm.value !== null && this.searchDomain.value !== null) {
            this.isSearching = true;
            this.screenMessage = ''; //hide the no records found message

            if (this.searchType.value === GroupMemberType.User && this.searchUserType.value !== null) {
                this.directoryUserService.SearchUsers(this.searchTerm.value, this.searchDomain.value, this.searchUserType.value)
                    .subscribe(this.searchObserver);
            }
            else if (this.searchType.value === GroupMemberType.Group) {
                this.directoryGroupService.SearchGroups(this.searchTerm.value ?? '', this.searchDomain.value,
                    {
                        removeNotAvailableAsGroupMember: this.filterGroupRemoveNotAvailableAsGroupMember,
                        removeDynamicMembershipEnabled: this.filterGroupRemoveDynamicMembershipEnabled,
                        onlyManagedByIdamp: this.filterGroupOnlyManagedByIdamp,
                    } as IDirectoryGroupFilter, undefined, this.aadGroupSearchScenario)
                    .subscribe(this.searchObserver);
            }
            else if (this.searchType.value === GroupMemberType.ServicePrincipal) {
                this.directoryAppService.SearchApplication(this.searchTerm.value ?? '').subscribe(this.servicePrincipalObserver);
            }
        }
    }

    async selectObject(directoryObject: DirectoryObjectBase) {
        if (this.type === GroupMemberType.Group && (this.groupLoadMembers || this.groupLoadOwners)) {
            this.isSearching = true;

            try {
                let result = await this.directoryGroupService.GetByIdAsync(directoryObject.id, directoryObject.domain);
                let group = result.data;

                if (this.groupLoadMembers) {
                    let memberResult = await this.directoryGroupService.GetMembersAsync(directoryObject.id, directoryObject.domain, this.groupMemberLimit);
                    group.members = memberResult.data;
                }

                if (this.groupLoadOwners) {
                    let ownerResult = await this.directoryGroupService.GetOwnersAsync(directoryObject.id, directoryObject.domain);
                    group.owners = ownerResult.data;
                }

                this.objectSelected.emit(group);
                this.isSearching = false;
            }
            catch (e) {
                this.screenMessage = `there was an error attempting to load the requested group "${directoryObject.displayName}" (${directoryObject.id})`
                this.isSearching = false;
            }
        }
        else if (this.type === GroupMemberType.ServicePrincipal) {
            this.isSearching = true;
            this.directoryAppService.GetApplication(directoryObject.id).subscribe({
                next: (x: IGenericApiResponse<IGraphApplication>) => {
                    directoryObject.id = x.data.servicePrincipalId;
                    this.objectSelected.emit(directoryObject);
                    this.isSearching = false;
                },
                error: (err?: any) => {
                    this.screenMessage = `there was an error attempting to load the selected service principal`;
                    this.isSearching = false;
                }
            });
        }
        else {
            this.objectSelected.emit(directoryObject);
        }
    }

    reset() {
        this.searchTerm.setValue('');
        this.searchResults = [];
    }
}