import { Dictionary } from "../../common/types/Dictionary";

export enum DataProtectionClassification {
    Tier3 = "Tier3",
    Tier2 = "Tier2",
    Tier1 = "Tier1",
}

export const DataProtectionClassificationDescriptions: Dictionary<DataProtectionClassification, string> = {
    Tier3: "Reviewed Annually: Company Confidential, Tier 3 Confidentiality, or Tier 3 Integrity",
    Tier2: "Reviewed Annually: Confidential Restricted-Access, Tier 2 Confidentiality, or Tier 2 Integrity",
    Tier1: "Reviewed Semi-Annually: Classified, In Scope for SOX, Tier 1 Confidentiality, or Tier 1 Integrity",
}

