import { AvailabilityRating } from "./enums/AvailabilityRating.enum";
import { ConfidentialityRating } from "./enums/ConfidentialityRating.enum";
import { DataIntegrityRating } from "./enums/DataIntegrityRating.enum";

/**
 * API interface for
 *   GET: /api/chevronapplications
 *   GET: /api/chevronapplications/{id}
 */
export interface IIdmApplication {
    serviceId: string,
    displayName: string,
    isActive: boolean,
    availabilityRating: AvailabilityRating,
    confidentialityRating: ConfidentialityRating,
    dataIntegrityRating: DataIntegrityRating,
    environments: string[],
}

/**
 * API model for
 *   GET: /api/chevronapplications
 *   GET: /api/chevronapplications/{id}
 */
export class IdmApplication implements IIdmApplication {
    serviceId: string = '';
    displayName: string = '';
    isActive: boolean = false;
    availabilityRating: AvailabilityRating = AvailabilityRating.Tier1;
    confidentialityRating: ConfidentialityRating = ConfidentialityRating.Tier1;
    dataIntegrityRating: DataIntegrityRating = DataIntegrityRating.Tier1;
    environments: string[] = [];
}

// this model is used for displaying the application environment options
export class EnvironmentOption {
    value: string = '';
    environmentName: string = '';
}