import { Component, ViewChild } from "@angular/core";
import { PageLayout } from "@cvx/nextpage";
import { DirectoryGroup } from "src/app/models/directory/groups/DirectoryGroup";
import { DirectoryGroupService } from "src/app/services/directory/DirectoryGroup.service";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { CalAngularService, ICvxClaimsPrincipal } from "@cvx/cal-angular";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { IDirectoryGroupFilter } from "src/app/models/services/IDirectoryGroupFilter";
import { DirectoryGroupUpdates, DirectoryGroupUpdatesForm } from "src/app/models/directory/groups/DirectoryGroupUpdates";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { DialogAppSearchChevronComponent } from "../../_shared/dialog-app-search-chevron/dialog-app-search-chevron.component";
import { IDialogAppSearchChevronData } from "src/app/models/idm/applications/IDialogAppSearchChevronData";
import { IIdmApplication, IdmApplication } from "src/app/models/idm/applications/IdmApplication";
import { MatDialog } from "@angular/material/dialog";
import { DataProtectionClassification } from "src/app/models/directory/enums/DataProtectionClassification.enum";
import { ToStringHelper } from "src/app/helpers/ToStringHelper";
import { AadGroupSearchScenario } from "src/app/models/directory/enums/AadGroupSearchScenario.enum";
import { group } from "console";
import { DirectoryGroupUpdateRequest } from "src/app/models/directory/groups/DirectoryGroupUpdateRequest";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";

@Component({
  selector: 'app-update-group',
  templateUrl: './update-group.component.html',
  styleUrl: './update-group.component.css'
})
export class UpdateGroupComponent {
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    this.groupsDataSource.paginator = value;
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.groupsDataSource.sort = value;
  }

  chevronApp: IIdmApplication | null = null;

  domain = DirectoryDomain.Chevron;
  gettingGroups: boolean = true;
  isSubmitting: boolean = false;
  selection = new SelectionModel<DirectoryGroup>(true, []);
  groupUpdatesDataSource = new MatTableDataSource<DirectoryGroupUpdatesForm>([]);

  private commonColumns: string[] = ['displayName', 'id', 'type', 'dataProtection'];
  groupsDisplayedColumns: string[] = ['select', ...this.commonColumns, 'applicationId'];
  groupsFormDisplayedColumns: string[] = [...this.commonColumns, 'serviceId'];
  groupsFormDataColumns: string[] = [...this.groupsFormDisplayedColumns, 'del'];
  PageLayout = PageLayout;
  submitErrorMessage = { message: '', errors: [] };
  submittedRequests: IWorkflowRequest[] = [];

  currentUserProfile: ICvxClaimsPrincipal;
  groupsDataSource = new MatTableDataSource<DirectoryGroup>([]);

  get dataProtectionClassifications() { return Object.values(DataProtectionClassification) };


  get GroupMemberType() {
    return GroupMemberType;
  }

  constructor(private directoryGroupService: DirectoryGroupService, private authService: CalAngularService, private dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    var filter = {
      removeNotAvailableAsGroupMember: false
      , removeDynamicMembershipEnabled: true
      , filterGroupRemoveNonAzureGroups: true
      , onlyManagedByIdamp: true
    } as IDirectoryGroupFilter;
    this.directoryGroupService.GetOwnedGroups(this.authService.cvxClaimsPrincipal.objectId, filter, undefined, AadGroupSearchScenario.GroupUpdate).subscribe({
      next: (x: IGenericApiResponse<DirectoryGroup[]>) => {
        this.groupsDataSource.data = x.data.filter(g => g.type == "Security")
        this.groupsDataSource.paginator = this.paginator
        this.groupsDataSource.sort = this.sort
      },
      error: (e) => {
        console.error(e);
        this.gettingGroups = false;
      },
      complete: () => this.gettingGroups = false
    });
  }
  deleteRow(row: DirectoryGroupUpdates) {
    this.groupUpdatesDataSource.data = [...this.groupUpdatesDataSource.data.filter(g => g.id != row.id)];
    this.selection.deselect(row);
  }
  addGroupUpdate(row: DirectoryGroup) {
    let groupUpdate = row as DirectoryGroupUpdatesForm;

    if (this.chevronApp != null) {
      groupUpdate.chevronApp = this.chevronApp;
      groupUpdate.newApplicationId = this.chevronApp.serviceId;
    }
    else if (groupUpdate.applicationId?.length > 0 && !Number.isNaN(parseInt(groupUpdate.applicationId))) {
      let serviceIdOnlyChevronApp = new IdmApplication();
      serviceIdOnlyChevronApp.serviceId = groupUpdate.applicationId;
      serviceIdOnlyChevronApp = ToStringHelper.overrideToString(serviceIdOnlyChevronApp);
      groupUpdate.chevronApp = serviceIdOnlyChevronApp;
    }
    else {
      groupUpdate.chevronApp = null;
    }
    groupUpdate.serviceId = groupUpdate.applicationId;

    if (groupUpdate.dataProtection.toString() == "None") {
      groupUpdate.newDataProtection = DataProtectionClassification.Tier1;
    }
    else {
      groupUpdate.newDataProtection = groupUpdate.dataProtection;
    }

    this.groupUpdatesDataSource.data = [...this.groupUpdatesDataSource.data, groupUpdate];
  }

  select(row: DirectoryGroup) {
    this.selection.toggle(row);

    // If the row is already in the group updates, remove it, otherwise add it
    if (this.groupUpdatesDataSource.data.find(g => g.id == row.id)) {
      this.deleteRow(row as DirectoryGroupUpdates);
    } else {
      this.addGroupUpdate(row);
    }
  }
  public doFilter = (value: any) => {
    this.groupsDataSource.filter = value.target.value.trim().toLocaleLowerCase();
  }

  isValid(groupUpdates: DirectoryGroupUpdates) {
    return groupUpdates.id !== null && (groupUpdates.newApplicationId !== null || groupUpdates.newDataProtection !== null || groupUpdates.newWritebackEnabled !== null);
  }
  async onSubmit() {

    // clear error messages
    this.submitErrorMessage.message = '';
    this.submitErrorMessage.errors = [];

    if (this.selection.selected.length > 0 && this.groupUpdatesDataSource.data.some(g => this.isValid(g))) {
      this.isSubmitting = true;
      let payload = new DirectoryGroupUpdateRequest(this.groupUpdatesDataSource.data.filter(g => this.isValid(g)));

      const observer = {
        next: (x: IGenericApiResponseWithWorkflowRequest<DirectoryGroup>) => {
          this.submittedRequests = x.requests;
        },
        error: (err: any) => {
          this.submitErrorMessage.message = err.statusText;
          this.submitErrorMessage.errors = err?.error?.errors ?? [];
          this.isSubmitting = false;
        },
        complete: () => {
          this.isSubmitting = false;
        }
      };

      let updateCall = this.directoryGroupService.UpdateGroup(this.domain, payload);
      updateCall.subscribe(observer);
    }
  }
  dataProtectionChanged(event: any, id: string) {
    this.groupUpdatesDataSource.data = [...this.groupUpdatesDataSource.data.map(g => {
      if (g.id === id) {
        g.newDataProtection = event.value;
      }
      return g;
    })];
  }

  searchForChevronApplication(id: string = ""): void {
    const appDialogRef = this.dialog.open(DialogAppSearchChevronComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%',
      data: {
        includeEnvironments: false
      } as IDialogAppSearchChevronData
    });

    appDialogRef.afterClosed().subscribe(result => {
      if (result) {
        let chevronApp = result as IIdmApplication;

        // override toString method to display displayName (as form control value will display [object Object] without this)
        chevronApp = ToStringHelper.overrideToString(chevronApp);

        if (id.length > 0) {
          this.groupUpdatesDataSource.data = [...this.groupUpdatesDataSource.data.map(g => {
            if (g.id === id) {
              g.newApplicationId = chevronApp.serviceId;
              g.chevronApp = chevronApp;
            }
            return g;
          })];
        }
        else {
          this.chevronApp = chevronApp;

          this.groupUpdatesDataSource.data = this.groupUpdatesDataSource.data.map(g => {
            g.newApplicationId = chevronApp.serviceId;
            g.chevronApp = chevronApp;
            return g;
          });
        }
      }
    });
  }
}
